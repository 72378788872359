@import "main-bx";

.form {
  display: flex;
  flex-direction: column;
  &-label {
    padding: 7px;
    border-bottom: 1px solid #000;
    margin-bottom: 40px;
    &__input {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      color: $color_black;
    }
  }
  &__checkbox {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-bottom: 40px;
    &-box {
      height: 16px;
      width: 16px;
      min-width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      background-color: transparent;
      border: 1px solid $color_black;
      & svg {
        display: none;
      }
    }
    & input:checked ~ .form__checkbox-box {
      border-color: $color_blue;
      svg {
        display: block;
      }
    }
    &-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 110%;
      color: rgba(0, 0, 0, 0.5);
      & a {
        color: rgba(0, 0, 0, 0.6);
        text-decoration: underline;
      }
    }
  }
}
