@import "main-bx";

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color_black;
  backdrop-filter: blur(12px);
  border-radius: 150px;
  padding: 16px 32px;
  color: $color_black;
  cursor: pointer;
  transition: $trns;
  &_white {
    background-color: $color_white;
    border: 1px solid $color_black;
    &:hover {
      background-color: $color_black;
      border-color: $color_white;
      color: $color_white;
    }
    &:active {
      background-color: $color_black;
      border-color: $color_white;
      color: $color_white;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
  &_black {
    background-color: $color_black;
    border: 1px solid $color_white;
    color: $color_white;
    &:hover {
      background-color: $color_white;
      border: 1px solid $color_black;
      color: $color_black;
    }
    &:active {
      background-color: $color_white;
      border: 1px solid $color_black;
      color: $color_black;
    }
    &:disabled {
      opacity: 0.3;
    }
  }
  &_blue {
    border-radius: 0;
    background-color: $color_blue;
    border: 1px solid $color_blue;
    color: $color_white;
    padding: 16px 32px;
    width: 100%;
    &:hover {
      background-color: $color_white;
      color: $color_blue;
      & svg {
        stroke: $color_blue;
      }
    }
    & svg {
      stroke: $color_white;
      margin-left: 10px;
    }
  }
}
