@import "main-bx";

html {
    //scroll-behavior: smooth;
    //scroll-padding: 60px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, ul, ol, p {
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;

    @include below(1380px) {
        max-width: 1200px;
        padding-left: 25px;
        padding-right: 25px;
    }

    @include below(1240px) {
        max-width: 1024px;
    }

    @include below(1024px) {
        max-width: 768px;
        padding-left: 20px;
        padding-right: 20px;
    }


    @include below(479px) {
        max-width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }
}

body {
    font-family: $font_second !important;
    font-size: 16px;
    line-height: 110%;
    width: 100%;
    max-width: $body_width;
    margin: 0 auto;
    background-color: $color_white;
    color: $color_black;
    overflow-x: hidden;

    &.compensate-for-scrollbar {
        margin-right: auto !important;
    }
}

a {
    text-decoration: none;
    transition: $trns;
}

input,
button,
textarea,
fieldset {
    background: transparent;
    border: 0;
    padding: 0;
}

button {
    cursor: pointer;
    transition: $trns;
}

img {
    display: block;
    width: 100%;
    height: auto;
    transition: $trns;
    object-fit: cover;
    max-width: 100%;
}

ul {
    list-style-type: none;
}


:focus {
    outline-style: none;
    outline-width: 0 !important;
    outline-color: none !important;
}

.none {
    display: none;
}
