@import "modules/footer/footer";
@import "modules/header/header";

@import "modules/button/button";
@import "modules/modal/modal";
@import "modules/form/form";

@import "modules/intro/intro";
@import "modules/service/service";
@import "modules/dealership/dealership";
@import "modules/auto-search/auto-search";
@import "modules/about/about";
@import "modules/number/number";
@import "modules/award/award";
@import "modules/worth/worth";
@import "modules/vacancy/vacancy";
@import "modules/contact/contact";
