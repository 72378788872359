@import "main-bx";

.footer {
    padding-top: 40px;
    padding-bottom: 40px;
    &__wrapper {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #000;
        padding-bottom: 20px;
        @include below(1024px) {
            flex-wrap: wrap;
        }
        @include below(650px) {
            flex-direction: column;
        }
    }
    &__name {
        font-family: $font_main;
        font-size: 18px;
        line-height: 110%;
        color: $color_black;
        margin-bottom: 18px;
        @include below(650px) {
            font-size: 14px;
        }
    }
    &__left {
        @include below(650px) {
            margin-bottom: 24px;
        }
    }
    &__tel {
        font-size: 32px;
        line-height: 110%;
        margin-bottom: 2px;
        color: $color_black;
        @include below(650px) {
            font-size: 24px;
        }
    }
    &__address {
        font-size: 14px;
        line-height: 110%;
        @include below(650px) {
            font-size: 12px;
        }
    }
    &__center {
        display: flex;
        @include below(650px) {
            justify-content: space-between;
        }
    }
    &__menu {
        margin-left: 25px;
        margin-right: 25px;
        @include below(768px) {
            margin-left: 15px;
            margin-right: 15px;
        }
        @include below(768px) {
            margin-left: 0;
            margin-right: 0;
        }
        & li {
            cursor: pointer;
        }
        & a {
            font-size: 16px;
            line-height: 110%;
            color: $color_black;
            margin-bottom: 4px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &__right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include below(650px) {
            margin-bottom: 24px;
        }
        & a {
            font-size: 12px;
            line-height: 110%;
            color: rgba(18, 18, 18, 0.4);
            margin-top: 6px;
            &:hover {
                color: $color_black;
            }
        }
    }
    &__copyright {
        font-size: 12px;
        line-height: 110%;
        color: #000000;
        opacity: 0.4;
        padding-top: 10px;
    }
}
