@import "main-bx";

.header {
  padding-top: 25px;
  & .container {
    padding-bottom: 55px;
    border-bottom: 1px solid #000;
    @include below(1024px) {
      padding-bottom: 6px;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    display: flex;
    & img {
      @include below(1024px) {
        width: 60px;
        height: 60px;
      }
    }
    & p {
      font-family: $font_main;
      font-size: 20px;
      line-height: 100%;
      color: $color_black;
      text-transform: uppercase;
      max-width: 300px;
      padding-left: 20px;
      padding-top: 20px;
      @include below(1024px) {
        font-size: 16px;
        padding-left: 10px;
        padding-top: 10px;
      }
      @include below(768px) {
        max-width: 140px;
        font-size: 12px;
        padding-left: 8px;
        padding-top: 4px;
      }
    }
  }
  &__right {
    display: flex;
  }
  &__menu {
    display: flex;
  }
  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include below(1024px) {
      display: none;
    }
    & a {
      color: $color_black;
      text-align: right;
      margin-bottom: 10px;
    }
  }
  &__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 50px;
    @include below(1024px) {
      display: none;
    }
  }
  &__tel {
    font-family: $font_main;
    font-size: 20px;
    line-height: 110%;
    color: $color_black;
    margin-bottom: 6px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      margin-left: 10px;
      fill: $color_black;
      stroke: $color_black;
    }
    &:hover {
      & svg {
        fill: $color_white;
        stroke: $color_white;
      }
    }
  }
  &__button {
    display: none;
    @include below(1024px) {
      display: block;
      background-color: transparent;
    }
  }
  &__icon-burger {
    display: none;
    @include below(1024px) {
      display: block;
    }
  }
  &__icon-close {
    display: none;
    @include below(1024px) {
      display: none;
    }
  }
  &.open {
    background-color: $color_white;
    position: relative;
    z-index: 10;
    .header {
      &__icon-burger {
        display: none;
      }
      &__icon-close {
        display: block;
        right: 25px;
      }
      &__menu {
        position: absolute;
        top: 95px;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: $color_white;
        padding-top: 80px;
        padding-bottom: 200px;
        padding-left: 15px;
        padding-right: 15px;
        backdrop-filter: blur(5px);
      }
      &__nav {
        display: flex;
        padding-bottom: 60px;
        & a {
          padding-bottom: 40px;
          width: 100%;
          text-align: center;
          margin-right: 0;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &__contacts {
        display: flex;
        align-items: center;
        margin-left: 0;
      }
      &__tel {
        margin-bottom: 24px;
      }
      &__button {
        &.button {
          background-color: $color_black;
          &:hover {
            border: 1px solid $color_black;
            background-color: $color_white;
          }
          & svg {
            fill: $color_black;
          }
        }
      }
    }
  }
}
