@import "main-bx";

.about {
  padding-bottom: 80px;
  @include below(768px) {
    padding-bottom: 40px;
  }
  &__title {
    @include title2();
    padding-top: 20px;
    border-top: 1px solid $color_black;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 60px;
    @include below(1240px) {
      flex-direction: column;
    }
  }
  &__left {
    width: 100%;
    max-width: 310px;
    @include below(1240px) {
      margin-bottom: 24px;
    }
    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      color: $color_black;
    }
  }
  &__right {
    width: 100%;
    max-width: 812px;
    @include below(1240px) {
      max-width: 100%;
    }
    & p {
      font-family: $font_main;
      font-weight: 400;
      font-size: 60px;
      line-height: 100%;
      color: $color_black;
      @include below(1240px) {
        font-size: 48px;
      }
      @include below(768px) {
        font-size: 24px;
      }
    }
  }
  &__bottom {

  }
  &__step {
    display: flex;
    justify-content: space-between;
    @include below(768px) {
      flex-direction: column;
    }
    &-left {
      width: 100%;
      max-width: 548px;
      padding: 33px 38px 21px 20px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 175px;
      border: 1px solid $color_black;
      border-collapse: collapse;
      position: relative;
      @include below(1380px) {
        max-width: 400px;
      }
      @include below(1024px) {
        padding: 30px 30px 20px 20px;
        height: 155px;
        max-width: 300px;
        flex-direction: row;
      }
      @include below(768px) {
        max-width: 100%;
      }
      &::after {
        position: absolute;
        content: "";
        width: 246px;
        height: 336px;
        transform: rotate(45deg);
        border-bottom: 1px solid $color_black;
        margin: 0 37px 16px 62px;
        @include below(1024px) {
          width: 215px;
          height: 117px;
          margin: -53px 0 16px -10px;
        }
      }
    }
    &-right {
      width: 100%;
      max-width: 812px;
      padding: 40px 40px 0 40px;
      border: 1px solid $color_black;
      border-collapse: collapse;
      @include below(1240px) {
        padding: 38px 38px 0 38px;
      }
      @include below(1024px) {
        padding: 20px 20px 0 20px;
      }
      @include below(768px) {
        padding: 20px;
      }
    }
  }
  &__key {
    font-family: $font_main;
    font-weight: 400;
    font-size: 32px;
    line-height: 90%;
    color: $color_blue;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include below(1024px) {
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-start;
    }
    & svg {
      stroke: $color_black;
      margin-left: 32px;
      @include below(1024px) {
        margin-left: 0;
        margin-top: 24px;
      }
      @include below(768px) {
        transform: rotate(90deg);
      }
    }
  }
  &__number {
    font-family: $font_main;
    font-weight: 400;
    font-size: 24px;
    line-height: 110%;
    color: $color_blue;
    @include below(1024px) {
      display: flex;
      align-items: flex-end;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: $color_black;
    @include below(1024px) {
      font-size: 16px;
    }
  }
}
