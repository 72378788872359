@mixin h1() {
    margin-top: 20px;
    margin-bottom: 20px;
}

@mixin title2() {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    text-transform: uppercase;
    color: $color_black;
    margin-bottom: 32px;
    @include below(1024px) {
        margin-bottom: 16px;
    }
}

@mixin font-face($name, $path, $weight, $style) {
    @font-face {
        font-family: $name;
        src: url($path + ".woff2") format("woff2"),
        url($path + ".woff") format("woff");
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

@mixin below($width) {
    @media (max-width: $width) {
        @content;
    }
}

@mixin above($width) {
    @media (min-width: $width) {
        @content;
    }
}

@mixin below_and_above($max,$min) {
    @media (max-width: $max) and (min-width: $min) {
        @content;
    }
}
