@import "main-bx";

.contact {
  background-color: $color_black;
  padding-top: 40px;
  padding-bottom: 40px;
  &__title {
    @include title2();
    color: $color_white;
    padding-top: 20px;
    border-top: 1px solid $color_white;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include below(1240px) {
      flex-direction: column;
    }
  }
  &__left {
    width: 100%;
    max-width: 350px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: $color_white;
    @include below(1240px) {
      margin-bottom: 32px;
    }
  }
  &__right {
    width: 100%;
    max-width: 812px;
    @include below(1240px) {
      max-width: 100%;
    }
  }
  &__link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    & svg {
      margin-left: 10px;
      stroke: $color_white;
    }
    &:hover {
      & svg {
        stroke: $color_black;
      }
    }
    &_blue {
      background-color: $color_blue;
      border-color: $color_blue;
      width: calc(50% - 5px);
      @include below(768px) {
        width: 100%;
      }
    }
    &-list {
      display: flex;
      @include below(768px) {
        flex-direction: column;
      }
    }
  }
}
