@import "main-bx";

.worth {
  background-color: $color_blue;
  padding-top: 40px;
  padding-bottom: 40px;
  @include below(768px) {
    padding-bottom: 0;
  }
  &__title {
    @include title2();
    color: $color_white;
    padding-top: 20px;
    border-top: 1px solid $color_white;
  }
  &__left {
    width: 100%;
    max-width: 350px;
  }
  &__wrapper {
    width: fit-content;
    margin-left: auto;
    margin-top: -50px;
    @include below(1024px) {
      margin-top: 32px;
    }
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: $color_white;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include below(1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__item {
    color: $color_white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    max-width: 406px;
    min-height: 238px;
    padding: 25px;
    border: 1px solid $color_white;
    border-collapse: collapse;
    clip-path: polygon(0% 5%, 5% 5%, 5% 0%, 95% 0%, 95% 5%, 100% 5%, 100% 95%, 95% 95%, 95% 100%, 5% 100%, 5% 95%, 0% 95%);
    @include below(1024px) {
      min-width: 170px;
      padding: 15px;
      min-height: 214px;
    }
    &:first-child {
      grid-column: 2;
    }
    &:nth-child(5) {
      grid-column: 1 / 3;
      max-width: 100%;
      border: none;
      justify-content: center;
    }
    &:nth-child(6) {
      grid-row: 3;
      grid-column: 3;
      @include below(1024px) {
        grid-row: 4;
        grid-column: 2;
      }
    }
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: $font_main;
      font-weight: 700;
      font-size: 24px;
      line-height: 110%;
      @include below(550px) {
        font-size: 12px;
        flex-direction: column-reverse;
        align-items: flex-start;
        margin-bottom: 15px;
      }
      & svg {
        @include below(550px) {
          width: 24px;
          height: 24px;
          margin-bottom: 15px;
        }
      }
    }
    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      @include below(550px) {
        font-size: 12px;
        line-height: 120%;
      }
      &_small {
        @include below(768px) {
          font-size: 16px;
        }
        @include below(550px) {
          font-size: 16px;
        }
      }
      &_big {
        font-family: $font_main;
        font-weight: 400;
        font-size: 60px;
        line-height: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        @include below(768px) {
          font-size: 32px;
        }
        & svg {
          stroke: $color_white;
          margin-top: -16px;
          margin-right: 22px;
          @include below(768px) {
            width: 15px;
            margin-right: 18px;
          }
        }
        .slick-slide {
          display: flex !important;
        }
      }
      &_toggle {
        display: none;
        &:first-child {
          display: flex;
        }
        & span {
          margin-top: -10px;
          @include below(768px) {
            margin-top: 0;
          }
        }
      }
    }
  }
}
