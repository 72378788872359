@import "main-bx";

.dealership {
  padding-top: 20px;
  padding-bottom: 80px;
  &__wrapper {
    padding-top: 20px;
    border-top: 1px solid $color_black;
    display: flex;
    justify-content: space-between;
    @include below(1240px) {
      flex-direction: column;
    }
  }
  &__left {
    width: 100%;
    max-width: 390px;
  }
  &__title {
    @include title2();
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: $color_black;
    margin-bottom: 32px;
  }
  &__right {
    width: 100%;
    max-width: 812px;
  }
  &__brand {
    width: 272px;
    height: 205px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid $color_black;
    border-collapse: collapse;
    clip-path: polygon(0% 5%, 5% 5%, 5% 0%, 95% 0%, 95% 5%, 100% 5%, 100% 95%, 95% 95%, 95% 100%, 5% 100%, 5% 95%, 0% 95%);
    cursor: pointer;
    @include below(1240px) {
      width: 100%;
    }
    @include below(768px) {
      height: 115px;
      min-width: 172px;
    }
    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include below(650px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    & a {
      font-weight: 700;
      font-size: 20px;
      line-height: 95%;
      color: $color_black;
      @include below(768px) {
        font-size: 16px;
      }
    }
    &:hover {
      background-color: #0717C0;
      clip-path: none;
      & a {
        color: $color_white;
      }
      & svg {
        stroke: $color_white;
      }
      & img {
        display: none;
      }
    }
  }
  &__logo {
    display: flex;
    justify-content: space-between;
    & img {
      height: 60px;
      @include below(768px) {
        height: 40px;
      }
    }
    & svg {
      stroke: $color_black;
      width: 24px;
      height: 24px;
      margin-top: 15px;
      @include below(768px) {
        width: 16px;
        height: 16px;
        margin-top: 9px;
      }
    }
  }
}
