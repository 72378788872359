$font_base: sans-serif;
$font_main: "Xolonium", $font_base;
$font_second: "Steppe", $font_base;
$font_third: "Onest", $font_base;

$color_white: #FFF;
$color_black: #121212;
$color_blue: #0717C0;

$breaks: (
        desktop_lg: 1600px,
        desktop: 1440px,
        laptop: 1380px,
        tablet: 1100px,
        tablet_md: 1024px,
        mobile: 768px,
        mobile_sm: 575px,
        mobile_xs: 479px,
        mobile_xxs: 375px,
);

$container: (
        desktop_lg: 1420px,
        desktop: 1400px,
        laptop: 1024px,
        tablet: 768px,
        mobile: 345px,
        mobile_xs: 280px,
);

$body_width: 1440px;


$trns: all 0.3s linear;
