@import "main-bx";

.auto-search {
  padding-bottom: 80px;
  @include below(768px) {
    padding-bottom: 40px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    background-color: $color_black;
    padding: 30px 20px;
    @include below(1024px) {
      padding: 20px;
      flex-direction: column;
    }
  }
  &__left {
    width: 100%;
    max-width: 350px;
    @include below(1024px) {
      margin-bottom: 24px;
    }
    & p {
      font-weight: 500;
      font-size: 20px;
      line-height: 120%;
      text-transform: uppercase;
      color: $color_white;
    }
  }
  &__right {
    width: 100%;
    max-width: 812px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include below(600px) {
      flex-direction: column;
    }
    & p {
      font-family: $font_main;
      font-weight: 400;
      font-size: 32px;
      line-height: 100%;
      text-transform: uppercase;
      color: $color_white;
      max-width: 530px;
      @include below(768px) {
        font-size: 24px;
      }
      @include below(600px) {
        margin-bottom: 40px;
      }
    }
    & a {
      min-width: 236px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include below(600px) {
        margin-left: auto;
      }
      @include below(500px) {
        width: 100%;
      }
      & svg {
        stroke: $color_black;
        margin-left: 9px;
      }
      &:hover {
        & svg {
          stroke: $color_white;
        }
      }
    }
  }
}
