@import "main-bx";

.modal {
  display: none;
  &__title {
    font-family: $font_main;
    font-weight: 400;
    font-size: 24px;
    line-height: 95%;
    text-transform: uppercase;
    color: $color_black;
    text-align: center;
    margin-bottom: 12px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    margin-bottom: 60px;
  }
  &__form {
    &-button {
      margin-bottom: 20px;
    }
    &-policy {
      &-text {
        font-weight: 400;
        font-size: 11px;
        line-height: 130%;
        color: #6D6E71;
        & a {
          color: $color_black;
        }
      }
    }
  }
  &__container {
    width: 100% !important;
    max-width: 803px !important;
    @include below(555px) {
      max-width: 345px !important;
    }
    &.fancybox-content {
      background-color: #fff;;
      padding: 40px 210px 60px;
      overflow: unset !important;
      @include below(768px) {
        padding: 50px 24px 24px 24px;
      }
    }
    & button.fancybox-button svg path {
      fill: $color_black;
    }
  }
}

.fancybox-bg {
   background: transparent !important;
 }

.fancybox-inner {
  background: rgba(0, 0, 0, 0.6);
}

.fancybox-close-small {
  padding: 8px!important;
}

.fancybox-button svg {
  width: 24px !important;
  height: 24px !important;
  fill: $color_white;
  & path {
    stroke-width: 1px !important;
    z-index: 999999 !important;
  }
  & rect {
    stroke: $color_black !important;
  }
}
