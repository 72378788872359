@import "main-bx";

.intro {
  padding-top: 10px;
  padding-bottom: 120px;
  @include below(768px) {
    padding-bottom: 60px;
  }
  @include below(450px) {
    padding-bottom: 40px;
  }
  &__wrapper {
    position: relative;
  }
  &__pictures {
    display: none;
    &:first-child {
      display: block;
    }
  }
  &__picture {
    & img {
      height: 500px;
      @include below(1024px) {
        height: 350px;
      }
      @include below(650px) {
        height: 250px;
      }
      @include below(450px) {
        height: 150px;
      }
    }
  }
  &__content {
    position: absolute;
    top: 20px;
    right: 20px;
    left: 0;
    width: 100%;
    height: 83%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    @include below(650px) {
      top: 5px;
      right: 5px;
    }
  }
  &__brand {
    font-family: $font_main;
    font-weight: 700;
    font-size: 18px;
    line-height: 95%;
    text-transform: uppercase;
    color: $color_black;
    border-right: 0.5px solid $color_black;
    padding-left: 20px;
    padding-top: 10px;
    cursor: pointer;
    @include below(1024px) {
      font-size: 12px;
    }
    @include below(650px) {
      font-size: 8px;
      padding-left: 5px;
      padding-top: 5px;
    }
    @include below(450px) {
      font-size: 6px;
    }
    &:hover {
      color: $color_white;
    }
    &.active {
      color: $color_white;
    }
  }
  &__title {
    position: absolute;
    width: 100%;
    top: 448px;
    @include below(1240px) {
      top: 452px;
    }
    @include below(1024px) {
      top: 314px;
    }
    @include below(650px) {
      top: 220px;
    }
    @include below(550px) {
      top: 225px;
    }
    @include below(450px) {
      top: 129px;
    }
    & img {
      max-width: 1060px;
      margin: 0 auto;
    }
  }
}
