@import "main-bx";

.vacancy {
  padding-bottom: 100px;
  padding-top: 100px;
  @include below(768px) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid $color_black;
    @include below(1240px) {
      flex-direction: column;
    }
  }
  &__left {
    width: 100%;
    max-width: 350px;
  }
  &__title {
    @include title2();
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    margin-bottom: 32px;
  }
  &__right {
    width: 100%;
    max-width: 812px;
    @include below(1240px) {
      max-width: 100%;
    }
  }
  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color_black;
    &:first-child {
      padding-top: 0;
    }
    @include below(650px) {
      flex-direction: column;
    }
  }
  &__job {
    font-weight: 600;
    font-size: 24px;
    line-height: 110%;
    color: #222222;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 410px;
    margin-right: 20px;
    @include below(650px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
      flex-direction: column;
      align-items: flex-start;
    }
    & svg {
      margin-right: 35px;
      stroke: $color_black;
      @include below(650px) {
        transform: rotate(90deg);
        margin-bottom: 24px;
        margin-right: 0;
      }
    }
  }
  &__salon {
    display: flex;
    width: 100%;
    max-width: 367px;
    @include below(650px) {
      max-width: 100%;
    }
  }
  &__pin {
    width: 15px;
    height: 15px;
    margin-right: 12px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    font-family: $font_third;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: #999999;
    & span {
      color: #212529;
      margin-bottom: 4px;
    }
  }
}
