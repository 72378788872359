@import "main-bx";

.number {
  background-color: $color_black;
  padding-top: 40px;
  padding-bottom: 40px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include below(1024px) {
      flex-direction: column;
    }
  }
  &__title {
    @include title2();
    color: $color_white;
    margin-bottom: 20px;
  }
  &__list {
    width: 100%;
    max-width: 812px;
    @include below(1024px) {
      max-width: 100%;
    }
    .slick-slide {
      display: flex!important;
      @include below(1024px) {
        margin-left: 30px;
        margin-right: 30px;
      }
    }
    .slick-prev,
    .slick-next {
      top: 50%;
      width: 15px;
      height: 12px;
      & svg {
        stroke: $color_white;
        width: 15px;
        height: 12px;
      }
      &:before {
        display: none;
      }
      &:hover,
      &:active {
        & svg {
          stroke: $color_white;
          opacity: 0.8;
        }
      }
    }
    .slick-next {
      right: 10px;
      z-index: 10;
      @include below(1024px) {
        right: 0px;
      }
    }
    .slick-prev {
      left: -40px;
      z-index: 10;
      @include below(1024px) {
        left: 0px;
      }
    }
  }
  &__item {
    display: none;
    justify-content: flex-start;
    align-items: center;
    @include below(600px) {
      flex-direction: column;
    }
    &:nth-child(1) {
      display: flex !important;
    }
  }
  &__num {
    font-family: $font_main;
    font-weight: 700;
    font-size: 280px;
    line-height: 95%;
    color: $color_white;
    margin-right: 30px;
    @include below(1024px) {
      font-size: 120px;
    }
    @include below(600px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  &__text {
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    color: $color_white;
    width: 100%;
    max-width: 375px;
    @include below(600px) {
      text-align: center;
    }
  }
}
