@import "main-bx";

.award {
  padding-top: 100px;
  padding-bottom: 100px;
  @include below(768px) {
    padding-top: 40px;
  }
  @include below(550px) {
    padding-bottom: 0;
  }
  &__title {
    @include title2();
    padding-top: 20px;
    border-top: 1px solid $color_black;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @include below(1024px) {
      flex-direction: column;
    }
  }
  &__left {
    width: 100%;
    max-width: 350px;
    @include below(1024px) {
      max-width: 100%;
    }
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    color: $color_black;
    margin-bottom: 60px;
    @include below(1024px) {
      margin-bottom: 24px;
    }
  }
  &__tab {
    font-family: $font_main;
    font-weight: 400;
    font-size: 16px;
    line-height: 95%;
    color: $color_black;
    margin-bottom: 14px;
    cursor: pointer;
    @include below(1024px) {
      margin-right: 24px;
      margin-bottom: 0;
    }
    @include below(550px) {
      text-align: center;
    }
    &:hover {
      color: $color_blue;
      & svg {
        stroke: $color_blue;
      }
    }
    &:focus {
      color: $color_blue;
      & svg {
        stroke: $color_blue;
      }
    }
    &:active {
      color: $color_blue;
      & svg {
        stroke: $color_blue;
      }
    }
    &.active {
      color: $color_blue;
    }
    & svg {
      margin-left: 47px;
      stroke: $color_black;
      @include below(1024px) {
        display: none;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      @include below(1024px) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 32px;
      }
    }
  }
  &__right {
    width: 100%;
    max-width: 812px;
    @include below(1024px) {
      max-width: 100%;
    }
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 57px 6px;
    margin-bottom: 57px;
    &.slick-initialized .slick-slide {
      height: auto;
      display: block;
    }
    .slick-slide {
      margin-left: 40px;
      margin-right: 40px;
    }
    .slick-prev,
    .slick-next {
      top: 37%;
      width: 15px;
      height: 12px;
      @include below(400px) {
        top: 31%;
      }
      @include below(350px) {
        top: 29%;
      }
      & svg {
        stroke: $color_black;
        width: 15px;
        height: 12px;
      }
      &:before {
        display: none;
      }
      &:hover,
      &:active {
        & svg {
          stroke: $color_blue;
        }
      }
    }
    .slick-next {
      right: 0px;
      z-index: 10;
    }
    .slick-prev {
      left: 0px;
      z-index: 10;
    }
  }
  &__item {
    width: 100%;
    cursor: pointer;
    position: relative;
    @include below(550px) {
      display: none;
    }
    &>p {
      font-family: $font_main;
      font-weight: 400;
      font-size: 16px;
      line-height: 95%;
      color: $color_black;
      margin-top: 10px;
      @include below(550px) {
        font-size: 22px;
        margin-bottom: 20px;
      }
    }
    &:hover {
      & .award__content {
        @include above(551px) {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(18, 18, 18, 0.8);
          padding: 20px;
        }
        @include above(768px) {
          padding: 30px;
        }
        &-title {
          @include above(551px) {
            font-family: $font_main;
            font-weight: 400;
            font-size: 24px;
            line-height: 90%;
            color: $color_white;
            margin-bottom: 30px;
          }
          @include above(650px) {
            font-size: 32px;
          }
        }
        &-text {
          @include above(551px) {
            font-family: $font_second;
            font-weight: 400;
            font-size: 14px;
            line-height: 120%;
            color: $color_white;
          }
          @include above(650px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  &__content {
    display: none;
    @include below(550px) {
      display: block;
    }
    &-title {
      @include below(550px) {
        display: none;
      }
    }
    &-text {
      @include below(550px) {
        font-family: $font_second;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: $color_black;
      }
    }
  }
  &__button {
    @include below(550px) {
      display: none;
    }
    & span {
      margin-top: 3px;
    }
  }
}
