@import "main-bx";

.service {
  padding-top: 80px;
  padding-bottom: 70px;
  @include below(1024px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &__title {
    @include title2();
    padding-top: 20px;
    border-top: 1px solid $color_black;
  }
  &__wrapper {

  }
  &__slider {
    &-nav {
      width: 100%;
      max-width: 740px;
      height: 57px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: 100px;
      @include below(1024px) {
        max-width: calc(100% - 60px);
        margin-right: auto;
        margin-bottom: 0px;
      }
      &-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &.slick-initialized .slick-slide {
          height: auto;
          display: block;
        }
        .slick-prev,
        .slick-next {
          top: 50%;
          width: 15px;
          height: 12px;
          & svg {
            stroke: $color_black;
            width: 15px;
            height: 12px;
          }
          &:before {
            display: none;
          }
        }
        .slick-next {
          right: -30px;
          @include below(1024px) {
            right: -20px;
          }
        }
        .slick-prev {
          left: -30px;
          @include below(1024px) {
            left: -20px;
          }
        }
      }
    }
    &-single {
      .slick-slide {
        display: flex!important;
      }
    }
  }
  &__nav {
    font-family: $font_main;
    font-weight: 700;
    font-size: 16px;
    line-height: 95%;
    color: #8A8F8D;
    text-align: center;
    cursor: pointer;
    padding: 20px 0;
    @include below(1024px) {
      padding: 8px 0;
    }
    &.slick-current {
      padding: 0;
      font-size: 60px;
      color: #000;
      @include below(1024px) {
        font-size: 32px;
      }
    }
  }
  &__item {
    display: none;
    height: fit-content;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    margin-left: 10px;
    &:nth-child(1) {
      display: flex!important;
    }
    @include below(1024px) {
      flex-direction: column;
    }
    &.slick-slide {
      height: fit-content;
    }
  }
  &__picture {
    width: 100%;
    max-width: 484px;
    margin-right: 30px;
    @include below(1024px) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 32px;
    }
  }
  &__content {
    width: 100%;
    max-width: 816px;
    @include below(1024px) {
      max-width: 100%;
    }
    &-title {
      font-family: $font_main;
      font-weight: 400;
      font-size: 50px;
      line-height: 95%;
      text-transform: uppercase;
      color: $color_black;
      margin-bottom: 32px;
      @include below(1380px) {
        font-size: 42px;
      }
      @include below(1024px) {
        font-size: 32px;
      }
    }
    &-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      color: #000;
      margin-bottom: 32px;
    }

  }
}
